.ant-form-item-label label {
  font-weight: 600 !important;
  font-size: 17px !important;
}
.form {
  padding: 2px;
  margin-top: 20px;
}

.form-container {
  padding-bottom: 60px !important;
}
.rightImg{
  background: url(https://t3.ftcdn.net/jpg/02/42/77/22/360_F_242772256_PRwokoyoXkDCIISNjfj9N3If0TPFtje8.jpg) no-repeat;
  object-fit: contain;
  color: white;
  text-align: center;
  font-size: 18px;
  mask-image: radial-gradient(circle at 100% 50%, transparent 0%, black 20%, black 80%, transparent 100%);
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
}