
/* Basic link styling */  
.link {
  cursor: pointer;
  color: #1e90ff; /* Link color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Make the link bold */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover state */
.link:hover {
  color: darkblue; /* Change color on hover */
  text-decoration: underline; /* Underline on hover */
}

/* Visited link styling */
.link:visited {
  color: #551a8b; /* Color for visited links */
}


.hidden{
  display: none !important;
}

