/* Profile Container */
.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

/* Header */
.profile-header {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

/* Forms List Header */
.forms-list-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}

/* Forms List */
.forms-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Form Item */
.form-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 6px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Form Title */
.form-title {
  font-size: 16px;
  color: #555;
  flex: 1;
  text-align: left;
}

/* Link Styling */
.form-link {
  color: #ff4d4f;
  text-decoration: none;
  font-size: 14px;
  display: flex;
}

.form-link:hover {
  color: #d9363e;
}

/* Tick Icon */
.tick-icon {
  color: #52c41a;
  font-size: 18px;
}

/* Cross Icon */
.cross-icon {
  color: #ff4d4f;
  margin-right: 5px;
  font-size: 18px;
}
