.rcw-response .rcw-message-text {
  max-width: 80%;
}

.rcw-conversation-container .rcw-header {
  padding: 10px;
}

.rcw-widget-container {
  z-index: 1000 !important;
}

#messages {
  height: auto !important;
  margin-top: auto;
}

.rcw-picker-btn,
.rcw-send {
  padding-bottom: 6px;
  border-radius: 0;
  border: none;
}

.rcw-conversation-container.active {
  width: 38vw;
}

@media screen and (max-width: 767px) {
  .rcw-conversation-container.active {
    min-width: 100vw !important;
  }
  .rcw-widget-container {
    height: 100% !important;
    background: #ffffff;
  }
}

.rcw-new-message {
  box-sizing: border-box;
  padding: 10px 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.rcw-launcher .rcw-badge {
  position: fixed;
  top: -7px;
  right: -1px;
  background-color: red;
  background: #6dc0fa !important;
  color: #fff;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #fff;
}

.rcw-sender {
  align-items: flex-end;
  background-color: #f4f7f9;
  border: 1px solid #ddd;
  border-radius: 0;
  display: flex;
  padding: 10px;
  height: max-content;
  min-height: 45px;
}

.rcw-conversation-container {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  min-width: 370px;
  max-width: 90vw;
}

.rcw-conversation-container .rcw-header {
  background-color: #6dc0fa;
  border-radius: 12px 12px 0 0;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

.rcw-response .rcw-message-text {
  background-color: #f4f7f9;
  border-radius: 12px 12px 12px 0;
  padding: 15px;
  max-width: 80%;
}

.quick-button {
  color: #0a66c2;
  border-radius: 12px;
  border: 1px solid #0a66c2;
  font-weight: 500;
  padding: 5px 10px;
  font-size: 17px;
  cursor: pointer;
  transition: background 0.2s ease;
  box-sizing: border-box !important;
}
.quick-buttons-container {
  padding-bottom: 20px;
}
.quick-button:hover,
.rcw-close-button,
.rcw-client .rcw-message-text {
  font-weight: 700;
  border-width: 2px;
}
.rcw-close-button {
  background-color: transparent !important;
}

ol {
  padding-inline-start: 25px;
}
ul {
  padding-inline-start: 25px;
}
