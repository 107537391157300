@import url("https://fonts.googleapis.com/css2?family=Ga Maamil:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* Font Faces */
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: circular;
  src: url("chrome-extension://liecbddmkiiihnedobmlmillhodjkdmb/fonts/CircularXXWeb-Book.woff2")
    format("woff2");
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: circular;
  src: url("chrome-extension://liecbddmkiiihnedobmlmillhodjkdmb/fonts/CircularXXWeb-Bold.woff2")
    format("woff2");
}

* {
  font-family: circular, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
html,
body {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
}
body {
  margin: 0;
  font-family: "Teachers", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* For WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 4px; /* Change to your preferred size */
}

::-webkit-scrollbar-track {
  background: transparent; /* Can be any color or transparent */
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); /* Slightly visible thumb */
  border-radius: 10px; /* Rounded scrollbar */
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.green {
  color: green !important;
}
.red {
  color: firebrick !important;
}

/*Google translate wrapper*/

.gtranslate_wrapper {
  z-index: 10000000000000 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

/* Dark mode styles - using invert filter */
body.dark-mode {
  filter: invert(1) hue-rotate(180deg);
}

/* Images and videos should be inverted back to their original colors */
body.dark-mode img,
body.dark-mode video {
  filter: invert(1) hue-rotate(180deg);
}
