/* Signature Container */
.signature-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

/* Signature Title */
.signature-title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #262626;
}

/* Info Links */
.info-links {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.info-link {
  padding: 0;
  height: auto;
  font-size: 14px;
}

/* Signature Preview */
.signature-preview {
  margin-top: 16px;
}

.signature-status {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.signature-check {
  color: #52c41a;
  display: flex;
  align-items: center;
  gap: 6px;
}

.signature-check span:first-child {
  font-size: 16px;
}

.signature-check span:last-child {
  font-size: 14px;
}

.edit-button {
  border-radius: 6px;
}

.signature-image {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: contain;
  background-color: #fafafa;
}

/* Sign Button */
.sign-button {
  width: 100%;
  height: 48px;
  font-size: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Consent Section */
.consent-section {
  margin-bottom: 20px;
}

.consent-checkbox-container {
  margin-bottom: 12px;
}

.consent-checkbox {
  display: flex;
  align-items: flex-start;
}

/* Signature Pad */
.signature-pad-container {
  border: 1px solid #000;
  width: 100%;
  height: 200px;
  max-width: 500px;
  margin: 0 auto;
}

/* Info Modal */
.info-modal {
  top: 20px;
}

.info-text {
  font-size: 16px;
  margin-bottom: 16px;
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.info-list-item {
  padding: 12px 16px;
  background-color: #fff8f8;
  border-left: 3px solid #ff4d4f;
  margin-bottom: 8px;
}

.important-details {
  margin-bottom: 32px;
}

.important-details h3 {
  font-size: 18px;
  margin-bottom: 16px;
}

.details-box {
  background-color: #fff1f0;
  border: 1px solid #ffccc7;
  border-radius: 8px;
  padding: 16px;
}

.details-list {
  margin: 0;
  padding-left: 20px;
  color: #cf1322;
}

.details-list li {
  margin-bottom: 8px;
}

.details-list li:last-child {
  margin-bottom: 0;
}

.understand-button {
  width: 100%;
}

/* Terms Modal */
.terms-modal {
  top: 20px;
}

.terms-modal .ant-modal-body {
  padding: 24px;
  max-height: 80vh;
  overflow-y: auto;
} 