
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Header Styles */
.header {
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
  transition: all 0.3s ease;
}

.header img {
  width: 60px;
  cursor: pointer;
}

.navbar a {
  margin: 0 1rem;
  color: #44b1f9;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s;
}

.navbar a:hover {
  color: #1f8dd6;
}

/* Top Section */
.top-section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(ellipse 200% 100% at bottom left, #44b1f9, #eaeefe 100%);
  position: relative;
  text-align: center;
  padding: 0 2rem;
}

.content-overlay {
  max-width: 800px;
  color: white;
}

.title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.highlight {
  color: #eaeefe;
}

.intro-text {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.start-chat-btn {
  padding: 1rem 3rem;
  border-radius: 50px;
  font-size: 1.2rem;
  background: white;
  color: #44b1f9;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.start-chat-btn:hover {
  background: #44b1f9;
  color: white;
}

/* Section Styles */
.section {
  width: 100%;
  max-width: 1000px;
  margin: 4rem 0;
  padding: 2rem;
  text-align: center;
}

.section h2 {
  font-size: 2.5rem;
  color: #44b1f9;
  margin-bottom: 1rem;
}

.program-card {
  padding: 1.5rem;
  border-radius: 15px;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.program-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.program-card h3 {
  font-size: 1.5rem;
  color: #333;
}

.start-btn {
  background: #44b1f9;
  color: white;
  border-radius: 20px;
  margin-top: 1rem;
  border: none;
  transition: background 0.3s ease;
}

.start-btn:hover {
  background: #1f8dd6;
}

/* Contact Section */
.contact-section {
  background: #f0f8ff;
  padding: 3rem 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-section p {
  font-size: 1rem;
}

/* Footer Styles */
.footer {
  width: 100%;
  text-align: center;
  padding: 2rem;
  background: #44b1f9;
  color: white;
}

.footer a {
  color: #eaeefe;
  margin: 0 0.5rem;
  text-decoration: none;
  transition: color 0.3s;
}

.footer a:hover {
  color: #fff;
}

/* Adaptive Styles */
@media (max-width: 768px) {
  .header {
    padding: 0.5rem 1rem;
  }

  .navbar a {
    font-size: 0.9rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .intro-text {
    font-size: 1rem;
  }

  .start-chat-btn {
    padding: 0.8rem 2rem;
    font-size: 1rem;
  }

  .section {
    padding: 1rem;
  }
}
